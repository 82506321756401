import { graphql, Link, PageProps } from "gatsby"
import * as React from "react"
import "twin.macro"
import Gallery from "../components/Gallery"
import Container from "../components/Container"
import Markdown from "../components/Markdown"
import Seo from "../components/seo"
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image"
import Button from "../components/Button"

interface IndexProps extends PageProps {
  data: {
    file: {
      childMarkdownRemark: {
        frontmatter: {
          title: string
          intro: string
          image: {
            childImageSharp: {
              gatsbyImageData: ImageDataLike
            }
          }
        }
      }
    }
  }
}

const Index = ({ data }: IndexProps) => {
  const { intro, title, image } = data.file.childMarkdownRemark.frontmatter

  const imageData =
    image !== null && image !== undefined
      ? getImage(image.childImageSharp.gatsbyImageData)
      : null

  return (
    <>
      <Seo title="Home" />
      {imageData && (
        <GatsbyImage
          image={imageData}
          loading="eager"
          alt="hero image"
          tw="h-[75vh] md:h-[65vh] lg:h-[82vh] xl:h-[85vh] w-full"
        />
      )}
      <Container>
        <div tw="flex flex-col gap-20 items-center">
          <div
            tw="w-full grid gap-8"
            style={{
              gridTemplateColumns: "1fr minmax(0, 65ch) 1fr",
            }}
          >
            <div
              style={{ gridColumn: 2 }}
              tw="flex flex-col gap-8 items-center row-start-2 lg:row-start-1"
            >
              {" "}
              <h1 tw="text-3xl font-display">Hello! I'm Santina,</h1>
              <div tw="prose text-center mb-4">
                <p>
                  an artist who understands the play of colors and forms as a
                  language that speaks directly to the soul. My paintings are
                  emotions, they whisper stories, and they dance in a harmony
                  that makes the invisible visible.
                </p>
                <p>
                  Each piece tells a different story, each painting brings light
                  and vibrancy to the room where it finds its place.
                </p>
                <p tw="text-lg text-yellow-700 italic font-display font-semibold">
                  Let yourself be touched by the power of colors and discover
                  how my art can transform your everyday life into a radiant
                  journey!
                </p>
              </div>
              <Link to="/gallery">
                <Button>Explore</Button>
              </Link>
            </div>

            <div tw="flex w-full items-center justify-center row-start-1 col-start-1 lg:col-start-1 col-span-3 lg:col-span-1">
              <StaticImage
                alt="a photo of Santina"
                src="../images/profile.png"
                tw="w-[200px] h-[200px] rounded-full"
              />
            </div>
          </div>
          <h2 tw="text-3xl font-display">Newest artworks</h2>
        </div>
        <Gallery limit={6} link={true} />
      </Container>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    file(sourceInstanceName: { eq: "pages" }, name: { eq: "home" }) {
      childMarkdownRemark {
        frontmatter {
          intro
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
