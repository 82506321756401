import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, ImageDataLike } from "gatsby-plugin-image"
import "twin.macro"
import Button from "./Button"
import GalleryImage from "./GalleryImage"

interface DrawingFrontmatter {
  title: string
  slug: string | null
  sold: boolean | null
  image: {
    childImageSharp: {
      gatsbyImageData: ImageDataLike
    }
  }
  imageCloseup: {
    childImageSharp: {
      gatsbyImageData: ImageDataLike
    }
  } | null
}

interface GalleryData {
  allFile: {
    nodes: Array<{
      id: string
      childMarkdownRemark: {
        frontmatter: DrawingFrontmatter
      }
    }>
  }
}

interface GalleryProps {
  limit?: number
  link?: boolean
}

const Gallery: React.FC<GalleryProps> = ({ limit, link }) => {
  limit = limit ?? -1
  link = link ?? false

  const data = useStaticQuery<GalleryData>(graphql`
    query {
      allFile(
        sort: {
          order: DESC
          fields: childrenMarkdownRemark___frontmatter___date
        }
        filter: {
          sourceInstanceName: { eq: "paintings" }
          name: { eq: "index" }
        }
      ) {
        nodes {
          id
          childMarkdownRemark {
            frontmatter {
              title
              slug
              sold
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: TRACED_SVG
                    aspectRatio: 1
                  )
                }
              }
              imageCloseup {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: TRACED_SVG
                    aspectRatio: 1
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  let nodesToProcess = data.allFile.nodes

  if (0 < limit && limit < nodesToProcess.length) {
    nodesToProcess = nodesToProcess.slice(0, limit)
  }

  const images = nodesToProcess.map(node => {
    const {
      id,
      childMarkdownRemark: {
        frontmatter: {
          title,
          slug,
          image: {
            childImageSharp: { gatsbyImageData: image },
          },
          imageCloseup,
          sold,
        },
      },
    } = node

    let closeUp = imageCloseup?.childImageSharp?.gatsbyImageData

    return (
      <GalleryImage
        id={id}
        slug={slug}
        image={image}
        closeUp={closeUp}
        title={title}
        sold={sold}
      />
    )
  })

  return (
    <div tw="grid grid-cols-2 lg:grid-cols-3 place-items-center gap-4 lg:gap-16 py-16">
      {images}
      {link && (
        <div tw="col-span-full w-full flex justify-center">
          <Link to="/gallery">
            <Button type="button">See Gallery to view paintings</Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default Gallery
