import { graphql, useStaticQuery, Link } from "gatsby"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import "twin.macro"

export interface GalleryImageProps {
  id: string
  slug: string | null
  image: ImageDataLike
  closeUp: ImageDataLike | null
  title: string
  sold: boolean
}

const hasImage = (image?: IGatsbyImageData | null): image is IGatsbyImageData =>
  image !== undefined && image !== null

const generateImage = (
  alt: string,
  image?: IGatsbyImageData,
  closeUp?: IGatsbyImageData | null
) => {
  const imageIsSet = hasImage(image)
  const closeUpIsSet = hasImage(closeUp)

  if (imageIsSet && closeUpIsSet) {
    return (
      <div tw="relative">
        <GatsbyImage
          image={closeUp}
          alt={alt}
          tw="absolute top-0 left-0 z-10 w-full h-full opacity-0 group-hover:opacity-100 transition duration-500 ease-out"
        />
        <GatsbyImage image={image} alt={alt} />
      </div>
    )
  }

  if (imageIsSet) {
    return (
      <div tw="transform-gpu transition duration-500 ease-out group-hover:scale-105">
        <GatsbyImage image={image} alt={alt} />
      </div>
    )
  }

  return <></>
}

const GalleryImage: React.FC<GalleryImageProps> = ({
  id,
  slug,
  image: imageData,
  closeUp: closeUpData,
  title,
  sold,
}) => {
  const image = getImage(imageData)
  const closeUp = closeUpData ? getImage(closeUpData) : null

  const url = slug ?? id

  return (
    <Link
      to={`/gallery/${url}`}
      key={id}
      tw="flex flex-col h-full rounded-lg overflow-hidden"
      className="group"
    >
      <div tw="flex-none relative overflow-hidden">
        {generateImage(title, image, closeUp)}
        {sold && (
          <div tw="absolute z-10 bottom-0 right-0 p-4 pointer-events-none">
            <div tw="px-4 py-2 bg-gray-500 bg-opacity-60 font-display text-white uppercase backdrop-filter backdrop-blur">
              sold
            </div>
          </div>
        )}
      </div>
      <div tw="flex flex-grow justify-center text-center p-4 font-display text-xl">
        {title}
      </div>
    </Link>
  )
}

export default GalleryImage
